var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_vm._m(0),_vm._l((_vm.$parent.form.tipos_servicios),function(tipoSer,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"card card-info card-outline collapsed-card"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"fas fa-clipboard-list"}),_vm._v(" Detalle "),_c('span',{staticClass:"badge badge-info"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_vm._m(1,true)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card"},[_vm._m(2,true),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm text-sm"},[_vm._m(3,true),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"btn-group"},[(_vm.acciones[index].indice == null)?_c('button',{staticClass:"btn bg-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addAsignacion(index)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
                                _vm.acciones[index].indice == 2 &&
                                  _vm.$parent.form.tipos_servicios.some((s) =>
                                    [3].includes(s.estado)
                                  )
                              )?_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelTipoServ(index)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(tipoSer.tipoServicio)+" ")]),_c('td',{staticClass:"text-center"},[(tipoSer.tipo_servicio == 1)?_c('div',[_vm._v(" "+_vm._s(tipoSer.tipo_vehiculo.nombre)+" "+_vm._s(tipoSer.tipoRemolque ? " - " + tipoSer.tipoRemolque : "")+" ")]):(tipoSer.tipo_servicio == 2)?_c('div',[_vm._v(" "+_vm._s(tipoSer.nTipoEquipo)+" ")]):(tipoSer.tipo_servicio == 3)?_c('div',[_vm._v(" "+_vm._s(tipoSer.tipoComplemen)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center"},[(tipoSer.tipo_cantidad == 1)?_c('div',[_c('span',{staticClass:"badge bg-lime"},[_vm._v("Días")])]):_c('div',[_c('span',{staticClass:"badge bg-navy"},[_vm._v("Viajes")])])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(tipoSer.cantidad_vehiculos)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(tipoSer.cantidad)+" ")]),_c('td',{staticClass:"text-center"},[(tipoSer.material_inventarios == 1)?_c('div',[_c('span',{staticClass:"badge badge-success"},[_vm._v("Si")])]):_c('div',[_c('span',{staticClass:"badge badge-danger"},[_vm._v("No")])])])])])])])]),(_vm.acciones[index].indice == 2)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._l((tipoSer.cantidad),function(cantVh,i){return [_c('div',{key:i,staticClass:"row justify-content-center",class:i > 0 ? 'mt-3' : ''},[(i > 0)?_c('hr',{staticClass:"col-md-12 w-100"}):_vm._e(),_c('div',{staticClass:"col-md-12 text-center"},[_c('strong',[_vm._v("Asignación "),_c('span',{staticClass:"badge alert-default-primary"},[_vm._v(_vm._s(i + 1))])])]),_c('div',{staticClass:"col-md-1"},[(
                            _vm.formTipo[index][i].fecha_inicial &&
                              _vm.formTipo[index][i].empresa_id &&
                              _vm.formTipo[index][i].cantidad_vehiculos > 0 &&
                              _vm.formTipo[index][i].cantidad_servicios > 0 &&
                              !_vm.formTipo[index][i].bloquear &&
                              _vm.$parent.form.tipos_servicios.some((s) =>
                                [3].includes(s.estado)
                              )
                          )?_c('button',{staticClass:"btn bg-success mt-3",attrs:{"type":"button"},on:{"click":function($event){[
                              _vm.saveUpdateTipoServSolicitud(index, i),
                              (_vm.formTipo[index][i].bloquear = true),
                              (_vm.formTipo[index][i].posicion = i),
                            ]}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),(
                            _vm.$parent.form.tipos_servicios.some((s) =>
                              [3].includes(s.estado)
                            ) &&
                              _vm.formTipo[index][i].bloquear &&
                              _vm.acciones[index].indice == 2 &&
                              (_vm.editableIndex === null || _vm.editableIndex === i)
                          )?_c('button',{staticClass:"btn bg-navy mt-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.editDet(index, i)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()]),_c('div',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"fecha_inical"}},[_vm._v("Fecha Inicial ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formTipo[index][i].fecha_inicial),expression:"formTipo[index][i].fecha_inicial"}],staticClass:"form-control form-control-sm",class:_vm.formTipo[index][i].fecha_inicial
                              ? 'border border-success'
                              : 'border border-danger',attrs:{"type":"date","id":"cantidad_vehiculos","disabled":_vm.formTipo[index][i].bloquear},domProps:{"value":(_vm.formTipo[index][i].fecha_inicial)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formTipo[index][i], "fecha_inicial", $event.target.value)}}})]),_c('div',{staticClass:"col-md-5"},[_c('label',{attrs:{"for":"empresa"}},[_vm._v("Empresa ")]),_c('v-select',{staticClass:"form-control form-control-sm p-0 table-responsive",class:[
                            _vm.formTipo[index][i].empresa_id
                              ? 'border border-success'
                              : 'border border-danger',
                            'h-30',
                            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                          ],attrs:{"append-to-body":true,"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas,"disabled":_vm.formTipo[index][i].bloquear},on:{"input":function($event){return _vm.selectEmpresa(index, i)}},model:{value:(_vm.empresa[index][i]),callback:function ($$v) {_vm.$set(_vm.empresa[index], i, $$v)},expression:"empresa[index][i]"}})],1),_c('div',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"cantidad_vehiculo"}},[_vm._v("Cantidad Vehículos ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formTipo[index][i].cantidad_vehiculos),expression:"formTipo[index][i].cantidad_vehiculos"}],staticClass:"form-control form-control-sm",class:_vm.formTipo[index][i].cantidad_vehiculos &&
                            _vm.formTipo[index][i].cantidad_vehiculos > 0
                              ? 'border border-success'
                              : 'border border-danger',attrs:{"type":"number","id":"cantidad_vehiculos","disabled":_vm.formTipo[index][i].bloquear},domProps:{"value":(_vm.formTipo[index][i].cantidad_vehiculos)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formTipo[index][i], "cantidad_vehiculos", $event.target.value)},function($event){return _vm.validarCantVh(index, i)}]}})]),_c('div',{staticClass:"col-md-2"},[_c('label',{attrs:{"for":"cantidad_servicios"}},[_vm._v("Cantidad Servicios ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formTipo[index][i].cantidad_servicios),expression:"formTipo[index][i].cantidad_servicios"}],staticClass:"form-control form-control-sm",class:_vm.formTipo[index][i].cantidad_servicios &&
                            _vm.formTipo[index][i].cantidad_servicios > 0
                              ? 'border border-success'
                              : 'border border-danger',attrs:{"type":"number","id":"cantidad_servicios","disabled":_vm.formTipo[index][i].bloquear},domProps:{"value":(_vm.formTipo[index][i].cantidad_servicios)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formTipo[index][i], "cantidad_servicios", $event.target.value)},function($event){return _vm.validarCantServ(index, i)}]}})])])]})],2)]):_vm._e()])]),(tipoSer.rutas != undefined)?_c('div',{staticClass:"card"},[_vm._m(4,true),_c('div',{directives:[{name:"show",rawName:"v-show",value:(tipoSer.rutas.length > 0),expression:"tipoSer.rutas.length > 0"}],staticClass:"col-md-12"},[_c('CsAsignacionesRutas',{ref:"csAsignacionesRutas",refInFor:true,attrs:{"indice":index,"dato":tipoSer}})],1)]):_vm._e(),(tipoSer.det_cargas != undefined)?_c('div',{staticClass:"card"},[_vm._m(5,true),_c('div',{directives:[{name:"show",rawName:"v-show",value:(tipoSer.det_cargas.length > 0),expression:"tipoSer.det_cargas.length > 0"}],staticClass:"col-md-12"},[_c('CsAsignacionesDetCarga',{ref:"csAsignacionesDetCarga",refInFor:true,attrs:{"indice":index,"dato":tipoSer}})],1)]):_vm._e()])])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-default-warning border border-warning col-md-12 shadow"},[_c('h5',{staticClass:"text-center"},[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención! ")]),_c('p',{staticClass:"text-center mb-0"},[_vm._v(" Es importante que los correos electrónicos estén debidamente parametrizados para las empresas con el fin de asegurar el envío correcto de los mismos. Si aún no se encuentran configurados, por favor, pónganse en contacto con el administrador de Frontera para realizar la parametrización correspondiente. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-plus"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header card text-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Tipos Vehículos")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray text-center"},[_c('tr',{staticClass:"text-center"},[_c('th',{staticClass:"text-nowrap"},[_vm._v(" Acciones ")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Tipo Servicio")]),_c('th',{staticClass:"text-nowrap"},[_vm._v(" Vehiculo/Equipo/Complementarios ")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Día/Viaje")]),_c('th',{staticClass:"text-nowrap"},[_vm._v(" Cantidad Vehículos a Solicitar ")]),_c('th',{staticClass:"text-nowrap"},[_vm._v(" Tiempo Requerido del Servicio (Dias / Cant) ")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Transporta Material")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header card text-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Rutas")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header card text-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',{staticClass:"mb-0"},[_c('span',{staticClass:"badge badge-info"},[_vm._v(" Detalle Carga ")])])])])])
}]

export { render, staticRenderFns }